import { AxiosResponse } from 'axios';
import { StrapiMetaPaginationType } from '../../types';
import http from '../../http';
import { RelatedOrganizationType } from './relatedOrganization.types';
export const GetRelatedOrganization = (
  locale: string,
) =>
  http
    .get(
      `/api/related-organizations?populate=deep&locale=${locale}&sort=DisplayOrder:asc`,
    )
    .then(
      (
        response: AxiosResponse<{
          data: RelatedOrganizationType[];
        }>,
      ) => response.data,
    );
